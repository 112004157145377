<template>
  <div id="module-stadium">
    <div class="card shadow">
      <div class="card-body p-0">
        <table class="table size-website">
          <thead>
            <tr>
              <th>
                <Order title="Tên sân" column="name" :callBack="orderBy" :orders="orders" />
              </th>
              <th>
                <Order title="Địa chỉ" column="address" :callBack="orderBy" :orders="orders" />
              </th>
              <th>
                <Order title="Ngày tạo" column="created_at" :callBack="orderBy" :orders="orders" />
              </th>
              <th style="width: 79px;">
                <button class="btn btn-success btn-sm btn-block" v-on:click="addItem()">
                  <i class="fas fa-plus-circle"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-if="items && items.items">
            <tr v-for="(item, index) in items.items" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.address }}</td>
              <td class="text-center">{{ formatDate(item.created_at) }}</td>
              <td>
                <div class="btn-group">
                  <button class="btn btn-info btn-sm" v-on:click="updateItem(item)"><i class="fas fa-edit"></i></button>
                  <button class="btn btn-default btn-sm" v-on:click="deleteItem(item)"><i
                      class="far fa-trash-alt"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
          <Paging v-if="items" :items="items" column="5" :callBack="pagingBy" />
        </table>
        <table class="table size-mobile">
          <thead>
            <tr>
              <th style="width: 79px;">
                <button class="btn btn-success btn-sm btn-block" v-on:click="addItem()">
                  <i class="fas fa-plus-circle"></i>
                </button>
              </th>
              <th>
                <Order title="Sân vận động" column="name" :callBack="orderBy" :orders="orders" />
              </th>
            </tr>
          </thead>
          <tbody v-if="items && items.items">
            <tr v-for="(item, index) in items.items" :key="index">
              <td>
                <div class="btn-group">
                  <button class="btn btn-info btn-sm" v-on:click="updateItem(item)"><i class="fas fa-edit"></i></button>
                  <button class="btn btn-default btn-sm" v-on:click="deleteItem(item)"><i
                      class="far fa-trash-alt"></i></button>
                </div>
              </td>
              <td>
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <div>{{ item.name }}</div>
                    <div><i class="text-sm">{{ item.address }}</i></div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div><span class="text-sm">{{ formatDate(item.created_at) }}</span></div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <Paging v-if="items" :items="items" column="5" :callBack="pagingBy" />
        </table>
      </div>
    </div>

    <b-modal centered scrollable no-close-on-backdrop :id="modalIdStadium"
      :title="itemChange && itemChange.id ? 'Cập nhật' : 'Thêm mới'">
      <div class="row admin-page" v-if="itemChange">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="control-label text-semibold label-valid">Tên sân</label>
            <input type="text" class="form-control" v-model="itemChange.name" id="name" placeholder="Tên sân"
              autocomplete="off">
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group mb-0">
            <label class="control-label text-semibold label-valid">Địa chỉ</label>
            <InputAddress :province="itemChange.province_id" :district="itemChange.district_id"
              :ward="itemChange.ward_id" :street="itemChange.street" :callBack="setAddress" />
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button class="btn btn-info btn-sm" @click="saveItem(itemChange)">
          <i class="fas fa-save mr-2"></i><span>Lưu</span>
        </button>
        <button class="btn btn-default btn-sm" @click="hideModal(modalIdStadium)">
          <i class="far fa-window-close mr-2"></i><span>Hủy</span>
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ApiStadium from "../../services/apiStadium";

export default {
  components: {
    Paging: () => import("../components/Paging.vue"),
    Order: () => import("../components/Order.vue"),
    InputAddress: () => import("../components/InputAddress.vue"),
  },
  data() {
    return {
      items: null,
      paging: null,
      orders: [{ column: "created_at", type: "desc" }],
      modalIdStadium: "stadium-modal",
      itemChange: null
    };
  },
  methods: {
    async initItems() {
      this.showLoading(true);
      const data = await ApiStadium.getPaging(this.paging, this.orders);
      this.showLoading(false);
      if (data && data.items) this.items = data;
    },
    pagingBy(paging) {
      this.paging = paging;
      this.initItems();
    },
    orderBy(orders) {
      this.orders = orders;
      this.initItems();
    },
    setAddress(p, d, w, s, a) {
      this.itemChange.province_id = p;
      this.itemChange.district_id = d;
      this.itemChange.ward_id = w;
      this.itemChange.street = s;
      this.itemChange.address = a;
    },
    makeItem() {
      return { id: "", name: "", province_id: "", district_id: "", ward_id: "", street: "", address: "" };
    },
    addItem() {
      this.itemChange = this.makeItem();
      this.showModal(this.modalIdStadium);
    },
    async updateItem(item) {
      this.itemChange = this.setObjectValue(this.makeItem(), item);
      this.showModal(this.modalIdStadium);
    },
    checkValid(item) {
      console.log("item", item);
      if (item) return this.checkValidForm([
        { id: "name", value: item.name, message: 'Vui lòng nhập Tên sân' },
        { id: "province", value: item.province_id, message: 'Vui lòng nhập Tỉnh/Thành phố' },
        { id: "district", value: item.district_id, message: 'Vui lòng nhập Quận/Huyện' },
      ]);
      return false;
    },
    async saveItem(item) {
      if (this.checkValid(item)) {
        this.showLoading(true);
        const data = await ApiStadium.addOrUpdate(item);
        this.showLoading(false);
        this.checkResponse(data, () => {
          this.hideModal(this.modalIdStadium);
          this.initItems();
        });
      }
    },
    async deleteItem(item) {
      if (!item.id) return;
      this.confirmDelete(this.$route.name, `${item.name}`).then(async (result) => {
        if (!result) return;
        this.showLoading(true);
        const data = await ApiStadium.delete(item.id);
        this.showLoading(false);
        this.checkResponse(data, () => {
          this.initItems();
        });
      })
    }
  },
  mounted() {
    if (this.isAdmin()) this.initItems();
    else this.$router.go(-1);
  }
};
</script>
